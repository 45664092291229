@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/_mixins';
@import 'Styles/partials/_functions';

$font-primary: 'Nunito', sans-serif;

$top-nav-height: 40px;
$main-nav-height: 88px;
$main-nav-height-mobile: 120px;
$nav-height-mobile: $top-nav-height + $main-nav-height-mobile;

$dropdown-height: 48px;

// bootstrap vars
$font-size-base: 16px;
$font-family-base: $font-primary;
$grid-gutter-width: 32px;

$spacers: (
  5: (
    $spacer * 2,
  ),
  6: (
    $spacer * 2.5,
  ),
  7: (
    $spacer * 3,
  ),
  8: (
    $spacer * 3.5,
  ),
  9: (
    $spacer * 4,
  ),
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1100px,
  xl: 1340px,
  xxl: 1440px,
);
